html,
.live {
  
  color: white;
  font-family: Calibri, sans-serif;
  background-color: #006fb7;

}

.live {
  overflow: hidden;
  height: 100vh;
}

.live table {
  width: 100%;
  border-collapse: collapse;
  background-color: #006fb7;
}

.live th,
.live td {
  padding: 8px;
  text-align: left;
  background-color: #006fb7;
  color: white;
  font-size: 74px;
  line-height: 74px;
  border-left: 0px !important;
  border-right: 0px !important;
  padding-bottom: 0px;
  padding-top: 0px;
}

.live th {

  padding-top: 0px;
  padding-bottom: 4px;
  font-size: 35px;
  line-height: 35px;
  background-color: #f6f5ed;
  color: #363635;
  border: none;
}

.live tr:nth-child(even) {
  background-color: #f6f5ed;
}

.live tr:hover {
  background-color: #ddd;
}

.live #table-times {
  width: 100%;
}

.live .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #1d1d1b;
  /* White background */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0;
}

.live .footer-logo {
  height: 160px;
  /* Adjust the height as needed */
  margin: 0 50px;
  /* Space between logos */
  background-color: white;
}

.live .header {
  background-color: #f6f5ed;
}

.live h1 {
  font-size: 100px;
  font-weight: normal;
  line-height: 100px;
}

.live .poweredBy {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  padding: 0 25px 0 0;
  margin: 0px;
}