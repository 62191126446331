html{
  background-color:white;
  color:black;
}

.tags-container {
    padding: 20px;
  }
  
  .search-container {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-tag-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .add-tag-container input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-tag-container button {
    padding: 10px 15px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-tag-container button:hover {
    background-color: #0056b3;
  }
  
  .tags-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .tags-table th,
  .tags-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    background-color:white;
    color:black;
    font-size: 15px
  }
  
  .tags-table th {
    background-color: #f4f4f4;
  }
  
  .tags-table input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #a71d2a;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }