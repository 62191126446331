.scroll-container {
    overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.sprint-header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 8px 12px;
    text-align: end;
}